<template>
  <div>
    <div class="banner" @click="goBuy">
      <img src="../../../assets/college/Accounts/liveInd.png" alt="" />
    </div>
    <div class="box">
      <img class="box_img1" src="../../../assets/college/Accounts/box_img1.png" alt="" />
      <div class="box_text1">
        <p class="tip1">支持语音/视频/课件多种形式直播</p>
        <p class="tip2">支持语音、实时视频、直播转录播、PPT课件、图片等多种形式的的直播形式</p>
      </div>
    </div>
    <div class="box">
      <div class="box_text2">
        <p class="tip1">一端直播多端观看</p>
        <p class="tip2">稳定的OBS推流直播，员工在小程序/PC都可观看</p>
      </div>
      <img class="box_img2" src="../../../assets/college/Accounts/box_img2.png" alt="" />
    </div>
    <div class="box1">
      <div class="text1">
        优惠灵活的收费方式
        <div class="line"></div>
      </div>
      <div class="flex">
        <div class="flex_box">
          <p class="flex_p">按每人每小时</p>
          <p class="flex_p1"><span class="flex_p2">￥5.80</span> /人/小时</p>
          <div class="btn">默认套餐</div>
        </div>
        <div class="flex_box">
          <p class="flex_p">直播100人并发</p>
          <p class="flex_p1"><span class="flex_p2">￥4600.00</span> /月</p>
          <div class="btn">套餐一</div>
        </div>
        <div class="flex_box">
          <p class="flex_p">直播200人并发</p>
          <p class="flex_p1"><span class="flex_p2">￥8000.00</span> /月</p>
          <div class="btn">套餐二</div>
        </div>
        <div class="flex_box">
          <p class="flex_p">直播500人并发</p>
          <p class="flex_p1"><span class="flex_p2">￥17000.00</span> /月</p>
          <div class="btn">套餐三</div>
        </div>
        <div class="flex_box">
          <p class="flex_p">直播1000人并发</p>
          <p class="flex_p1"><span class="flex_p2">￥38000.00</span> /月</p>
          <div class="btn">套餐四</div>
        </div>
      </div>
      <div class="jfx">
        <img src="../../../assets/college/Accounts/left_icon.png" alt="" />
        <div class="jfx_box">
          <p class="jfx1">计费项：</p>
          <p class="jfx2">1. 付费方式：预充值/预付费</p>
          <p class="jfx2">2. 每人每小时：充值余额后直播结束后按实际最高并发数和小时收费。</p>
          <p class="jfx2">
            3. 并发包月：预付费，包月套餐有效期内不限制直播次数，但每次直播不能超过套餐并发上限。
          </p>
          <p class="jfx1">收费示例：</p>
          <p class="jfx2">
            以按每人每小时为例：一场直播课上限100人，最高并发50人，直播持续50分钟（不足1小时按1小时算），
          </p>
          <p class="jfx2">那么实际花费为￥5.80元/每人每小时 * 1小时 * 50人 =￥290.00元。</p>
        </div>
      </div>
      <div class="ljkq" @click="goBuy">立即开启服务</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  created() {},
  methods: {
    goBuy() {
      this.$router.push('/live/liveMeal')
    },
  },
}
</script>

<style scoped lang="scss">
.banner img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.box {
  width: 100%;
  height: 510px;
  margin-top: -4px;
  background-color: #fff;
  position: relative;
}
.box_img1 {
  width: 639px;
  height: 386px;
  margin: 82px 0 0 166px;
}
.box_img2 {
  width: 704px;
  height: 446px;
  position: absolute;
  top: 20px;
  right: 200px;
}
.box_text1 {
  position: absolute;
  right: 200px;
  top: 170px;
}
.box_text2 {
  position: absolute;
  left: 250px;
  top: 170px;
}
.tip1 {
  font-size: 36px;
  font-family: Alibaba PuHuiTi, Alibaba PuHuiTi-Medium;
  font-weight: 500;
  color: #333333;
  line-height: 42px;
  letter-spacing: 1px;
}
.tip2 {
  margin-top: 20px;
  font-size: 18px;
  font-family: Alibaba PuHuiTi, Alibaba PuHuiTi-Medium;
  font-weight: 500;
  color: #606266;
  line-height: 42px;
  letter-spacing: 0px;
}
.box1 {
  width: 100%;
  height: 1028px;
  background: #e6eff8;
  box-sizing: border-box;
  padding: 59px 200px 0;
}
.text1 {
  font-size: 36px;
  font-family: Alibaba PuHuiTi, Alibaba PuHuiTi-Medium;
  font-weight: 500;
  text-align: center;
  color: #333333;
  line-height: 42px;
  letter-spacing: 1px;
  position: relative;
}
.line {
  width: 253px;
  height: 12px;
  background: #1369ec;
  position: absolute;
  left: 40%;
  top: 82%;
  opacity: 0.8;
}
.flex {
  margin-top: 65px;
  display: flex;
  justify-content: space-between;
}
.flex_box {
  width: 224px;
  height: 214px;
  background: #ffffff;
  border: 2px solid #54a4fe;
  border-radius: 10px;
}
.flex_p {
  margin-top: 25px;
  font-size: 18px;
  font-family: Alibaba PuHuiTi, Alibaba PuHuiTi-Medium;
  font-weight: 500;
  text-align: center;
  color: #333333;
  line-height: 42px;
  letter-spacing: 0px;
}
.flex_p1 {
  margin-top: 15px;
  font-size: 16px;
  font-family: Alibaba PuHuiTi, Alibaba PuHuiTi-Medium;
  font-weight: 500;
  text-align: center;
  color: #606266;
  line-height: 42px;
  letter-spacing: 0px;
}
.flex_p2 {
  font-size: 26px;
  font-family: Alibaba PuHuiTi, Alibaba PuHuiTi-Medium;
  font-weight: 500;
  text-align: center;
  color: #fbc300;
  line-height: 42px;
  letter-spacing: 1px;
}
.btn {
  width: 224px;
  height: 50px;
  background: #b3e2fe;
  margin-top: 36px;
  border-radius: 0px 0px 10px 10px;
  font-size: 18px;
  font-family: Alibaba PuHuiTi, Alibaba PuHuiTi-Medium;
  font-weight: 500;
  text-align: center;
  color: #0c62c8;
  line-height: 42px;
  letter-spacing: 0px;
  line-height: 50px;
}
.jfx {
  margin-top: 47px;
  width: 100%;
  height: 434px;
  background: #ffffff;
  position: relative;
}
.jfx img {
  position: absolute;
  width: 213px;
  height: 151px;
  left: 0;
  top: 0;
}
.jfx_box {
  position: absolute;
  z-index: 9999;
  left: 11%;
  top: 22%;
}
.jfx1 {
  font-size: 24px;
  font-family: Alibaba PuHuiTi, Alibaba PuHuiTi-Medium;
  font-weight: 500;
  color: #0c62c8;
  line-height: 55px;
  letter-spacing: 1px;
}
.jfx2 {
  font-size: 18px;
  font-family: Alibaba PuHuiTi, Alibaba PuHuiTi-Medium;
  font-weight: 500;
  color: #606266;
  line-height: 42px;
  letter-spacing: 0px;
}
.ljkq {
  width: 175px;
  height: 57px;
  line-height: 57px;
  margin: 53px auto 0;
  background-color: #1369ec;
  border-radius: 10px;
  font-size: 20px;
  font-family: Alibaba PuHuiTi, Alibaba PuHuiTi-Medium;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  letter-spacing: 1px;
  cursor: pointer;
}
</style>
